'use client';

import dynamic from 'next/dynamic';

import { useCookiesAccept, useIpWarningAccept, useRiskAccept } from '@/blocks/sections/Warnings/Model';
import { siteVersion } from '@/global/public-settings';

const CookieBanner = dynamic(() => import('./Cookie').then((m) => m.CookieBanner));
const RiskBanner = dynamic(() => import('./Risk').then((m) => m.RiskBanner));
const IpWarningBanner = dynamic(() => import('./IpWarning').then((m) => m.IpWarningBanner));

const BottomWarningsContainer = dynamic(() =>
  import('@/blocks/sections/Warnings/BottomWarningsContainer').then((m) => m.BottomWarningsContainer),
);

export const Warnings = ({ country }: { country: string }) => {
  const { showCookie, acceptCookie } = useCookiesAccept();
  const { showRisk, acceptRisk } = useRiskAccept();
  const { showIpWarning, acceptIpWarning } = useIpWarningAccept(country, siteVersion.isTurkey ? [] : ['TR']);

  if (!showRisk && !showCookie && !showIpWarning) {
    return null;
  }

  return (
    <>
      <BottomWarningsContainer>
        {showCookie ? <CookieBanner onCookieAccept={acceptCookie} /> : null}
        {showIpWarning ? <IpWarningBanner countryCode={country} onClose={acceptIpWarning} /> : null}
        {showRisk ? <RiskBanner onClose={acceptRisk} /> : null}
      </BottomWarningsContainer>
    </>
  );
};

export const WarningsPartners = () => {
  const { showCookie, acceptCookie } = useCookiesAccept();

  if (!showCookie) {
    return null;
  }

  return (
    <>
      <BottomWarningsContainer>
        {showCookie ? <CookieBanner onCookieAccept={acceptCookie} /> : null}
      </BottomWarningsContainer>
    </>
  );
};
