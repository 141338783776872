import { EnvNames, getEnv } from '@/global/env-utils';

export type TinyVersion = undefined | null | 'tr' | 'africa' | 'waitlist' | 'partners';

export const intercomId = getEnv({ envName: EnvNames.PRX_INTERCOM_ID });
export const gtmId = getEnv({ envName: EnvNames.PRX_GTM_ID });
export const amplitudeId = getEnv({ envName: EnvNames.PRX_AMPLITUDE_ID });
export const appsflyerId = getEnv({ envName: EnvNames.PRX_APPSFLYER_ID });
export const mainSiteDomain = getEnv({ envName: EnvNames.PRX_MAIN_SITE_DOMAIN, defaultValue: 'primexbt.com' });
export const partnersSiteDomain = getEnv({ envName: EnvNames.PRX_PARTNERS_DOMAIN, defaultValue: 'primexbt.partners' });
export const captchaKey = getEnv({ envName: EnvNames.PRX_CAPTCHA_KEY });
export const supportedLangs = getEnv({ envName: EnvNames.PRX_SUPPORTED_LANGS, optional: true });
export const defaultLang = getEnv({ envName: EnvNames.PRX_DEFAULT_LANG, defaultValue: 'en', optional: true });
export const tinyVersion: TinyVersion = getEnv({ envName: EnvNames.PRX_TINY_VERSION, optional: true });
export const nodeEnv = process.env.NODE_ENV;
export const bakstaApiEndpoint = 'api.baksta.com';

export const siteVersion = {
  isMain: !tinyVersion,
  isTurkey: tinyVersion === 'tr',
  isAfrica: tinyVersion === 'africa',
  // TODO ZA remove
  isWaitlist: tinyVersion === 'waitlist',
  isPartners: tinyVersion === 'partners',
};

// temp, better to move to supported features maybe, later
export const MT5_VIA_PXBT = false;

const isAfricaOrWaitlist = siteVersion.isAfrica || siteVersion.isWaitlist;

export const emailInfo = isAfricaOrWaitlist ? 'info@primexbt.co.za' : 'info@primexbt.com';
export const emailSupport = isAfricaOrWaitlist ? 'support@primexbt.co.za' : 'support@help.primexbt.com';
export const emailComplaints = isAfricaOrWaitlist ? 'complaints@primexbt.co.za' : 'complaints@primexbt.com';
export const emailPartners = isAfricaOrWaitlist ? 'partner@primexbt.co.za' : 'partner@primexbt.com';
export const emailPressAndMedia = isAfricaOrWaitlist ? 'pr@primexbt.co.za' : 'pr@primexbt.com';
// for now its the same
export const phone = isAfricaOrWaitlist ? '+27 63 006 3519' : '+27 63 006 3519';

export const RATINGS = {
  TRUSTPILOT: 4.3,
  GOOGLE_PLAY: 4.3,
  APPLE_STORE: 4.6,
  SITEJABBER: 4.7,
  FXEMPIRE: 4.7,
  REVIEWS_IO: 4.7,
  PRODUCTHUNT: 4.6,
  REVAIN: 4.6,
  G2: 4.6,
};

export function getEncodedPublicEnvs() {
  return btoa(
    JSON.stringify({
      [EnvNames.PRX_AMPLITUDE_ID]: amplitudeId,
      [EnvNames.PRX_APPSFLYER_ID]: appsflyerId,
      [EnvNames.PRX_GTM_ID]: gtmId,
      [EnvNames.PRX_INTERCOM_ID]: intercomId,
      [EnvNames.PRX_PARTNERS_DOMAIN]: partnersSiteDomain,
      [EnvNames.PRX_MAIN_SITE_DOMAIN]: mainSiteDomain,
      [EnvNames.PRX_TINY_VERSION]: tinyVersion,
      [EnvNames.PRX_SUPPORTED_LANGS]: supportedLangs,
      [EnvNames.PRX_DEFAULT_LANG]: defaultLang,
      [EnvNames.PRX_CAPTCHA_KEY]: captchaKey,
    }),
  );
}
