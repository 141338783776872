import { useEffect, useState } from 'react';

import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';

import { siteVersion } from '@/global/public-settings';
import { Region } from '@/global/region';
import { setCookie } from '@/utils/cookies';

export const partnersEUUKCookieName = 'prm-partners';

export const primeEUUKCookieNames: Record<Region, string> = {
  EU: 'prm-eu',
  GB: 'prm-uk',
};

// TODO maybe replace with session storage or cookies
export function useStorage({ key }: { key: string }) {
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(localStorage.getItem(key));
  }, []);

  return [
    value,
    (v: any) => {
      localStorage.setItem(key, v);
      setValue(v);
    },
  ];
}

export function useCookiesAccept() {
  const [value, setValue] = useStorage({ key: 'cookieAccepted' });
  return {
    showCookie: typeof value !== 'undefined' && !value,
    acceptCookie: () => setValue(true),
  };
}

export function useRiskAccept() {
  const [value, setValue] = useStorage({ key: 'riskWarningClosed' });
  return {
    showRisk: typeof value !== 'undefined' && !value,
    acceptRisk: () => setValue(true),
  };
}

export function useIpWarningAccept(country: string, forbiddenCountries: string[]) {
  const [value, setValue] = useStorage({ key: `ipWarningAcceptedCountries` });

  let acceptedCountries: string[] = [];
  try {
    const lsJSON = JSON.parse(value);
    if (Array.isArray(lsJSON)) {
      acceptedCountries = lsJSON;
    }
  } catch (e) {}

  return {
    showIpWarning:
      typeof value !== 'undefined' &&
      forbiddenCountries.includes(country) &&
      !acceptedCountries.includes(country),
    acceptIpWarning: () => setValue(JSON.stringify([...acceptedCountries, country])),
  };
}

// TODO maybe replace with session storage
export function useAcceptEUUK({
  cookies,
  cookieName,
}: {
  cookies: ReadonlyRequestCookies;
  cookieName: string;
}) {
  const cookieExists = Object.fromEntries(cookies)[cookieName];
  const [showPopup, setShowPopup] = useState(!cookieExists);
  const { acceptRisk } = useRiskAccept();

  return {
    showPopup: showPopup,
    accept: () => {
      setShowPopup(false);
      acceptRisk();
      setCookie(
        cookieName,
        JSON.stringify({
          createdAt: Date.now(),
          value: {
            accept: 1,
          },
        }),
      );
    },
  };
}
